import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_a_tabs = _resolveComponent("a-tabs")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_a_tabs, {
      class: "route-tabs",
      activeKey: _ctx.activeKey,
      "onUpdate:activeKey": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeKey) = $event)),
      onTabClick: _ctx.tabClick
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subRoutes, (route, index) => {
          return (_openBlock(), _createBlock(_component_a_tab_pane, { key: index }, {
            tab: _withCtx(() => [
              _renderSlot(_ctx.$slots, "tab", { route: route }, () => [
                _createTextVNode(_toDisplayString(route.meta.title), 1)
              ], true)
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 3
    }, 8, ["activeKey", "onTabClick"]),
    (_ctx.showRouterView)
      ? (_openBlock(), _createBlock(_component_router_view, _mergeProps({
          key: 0,
          class: "route-tab-content"
        }, _ctx.$attrs), null, 16))
      : _createCommentVNode("", true)
  ], 64))
}