import { useRouter, RouteRecordRaw, RouteRecordName } from 'vue-router'

function _findRoute(routes: RouteRecordRaw[], routeRecordName: RouteRecordName): RouteRecordRaw | null {
  for (const routeRecord of routes) {
    if (routeRecord.name === routeRecordName) {
      return routeRecord
    }
    if (routeRecord.children) {
      const route = _findRoute(routeRecord.children, routeRecordName)
      if (route) {
        return route
      }
    }
  }
  return null
}

export function findRoute(routeRecordName: RouteRecordName): RouteRecordRaw | null {
  const router = useRouter()
  const routes = router.getRoutes()
  return _findRoute(routes, routeRecordName)
}