
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";
import RouteTabs from "@/views/Payment/components/RouteTabs.vue";
import useMock from "@/utils/payment/useMock";
import { BUDataItem } from "@/views/User/ApprovalProcess/components/buTreeType";
import Data from "@/views/User/ApprovalProcess/components/mockBuTree";
import BUData from "@/views/User/ApprovalProcess/components/mockBu";
import { getBuList, getBuTreeList } from "@/API/approvalChain";
interface TreeDataItem {
  value: string;
  key: string;
  title?: string;
  slots?: Record<string, string>;
  children?: TreeDataItem[];
}
export default defineComponent({
  name: "App",
  props: {},
  setup() {
    const { state, commit } = useStore();
    const getBUList = () => {
      getBuList().then((data: any) => {
        commit(
          "approvalChain/updateBUListData",
          data?.map((item: any) => item.nameEn)
        );
      });
    };
    // 查询BU树
    const formatData = (data: any[]) => {
      data.forEach((item) => {
        item.title = item.nameEn;
        item.value = item.nameEn;
        item.key = item.id;
        if (item.children && item.children.length) {
          formatData(item.children);
        }
      });
    };
    const getBuTree = () => {
      getBuTreeList().then((data: any) => {
        formatData(data);
        commit("approvalChain/updateBUTreeData", data);
      })
    };
    const init = () => {
      getBUList()
      getBuTree()
    }
    init()
    return {};
  },
  components: {
    RouteTabs,
  },
});
