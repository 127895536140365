
import { defineComponent, shallowRef, computed } from "vue";
import { useRouter, useRoute, onBeforeRouteUpdate } from 'vue-router'
import type { RouteLocationNormalized } from 'vue-router'
import { findRoute } from '@/utils/payment/router'
import { btnPermissions } from "@/utils";

interface TabMenuPermission {
  path: string;
  privcd: string;
}

export default defineComponent({
  name: 'RouteTabs',
  props: {
    routeName: {
      type: [String, Symbol]
    }
  },
  setup(props) {
    const subRoutes = props.routeName ?
      findRoute(props.routeName)?.children?.filter((item) => {
        if (!item.meta?.showInTab) {
          return false
        }
        if (item.meta?.permission &&
          !btnPermissions((item.meta?.permission as TabMenuPermission).privcd, 
            (item.meta?.permission as TabMenuPermission).path)) {
          return false
        }
        return true
      }) : []
    const activeKey = shallowRef<number>(0)
    
    const updateActiveKey = (to: RouteLocationNormalized) => {
      subRoutes?.some((item, index) => {
        if (item.name == to.name || item.name == to.meta?.activeTabName) {
          activeKey.value = index
          return true
        }
      })
    }

    const route = useRoute()
    updateActiveKey(route)
    onBeforeRouteUpdate(updateActiveKey)

    const router = useRouter()
    const tabClick = (tab: string) => {
      if (subRoutes) {
        router.push({name: subRoutes[tab].name})
      }
    }

    const showRouterView = computed(() => {
      if (!route.meta?.permission) {
        return true
      }
      return btnPermissions((route.meta?.permission as TabMenuPermission).privcd, 
        (route.meta?.permission as TabMenuPermission).path)
    })

    return {
      subRoutes,
      activeKey,
      tabClick,
      showRouterView
    }
  }
})
